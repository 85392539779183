import { WorkOrder, WorkOrderSummary } from "@/data/schemas/work_order";
import { type ClassValue, clsx } from "clsx";
import { useEffect, useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";
import { z } from "zod";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function createInitials(name: string, surname?: string) {
  let fullName: string;

  if (surname !== undefined) {
    fullName = `${name.trim()} ${surname.trim()}`;
  } else {
    fullName = name;
  }

  const parts = fullName.split(" ");
  let initials = "";

  for (const part of parts) {
    if (part.length > 0) {
      initials += part[0];
    }
  }

  return initials.toUpperCase();
}

export function getDefaults<Schema extends z.AnyZodObject>(schema: Schema) {
  return Object.fromEntries(
    Object.entries(schema.shape).map(([key, value]) => {
      if (value instanceof z.ZodDefault)
        return [key, value._def.defaultValue()];
      return [key, undefined];
    })
  );
}
export function getDefaultsWithId<Schema extends z.AnyZodObject>(
  schema: Schema,
  id: string
) {
  return Object.fromEntries(
    Object.entries(schema.shape).map(([key, value]) => {
      if (key === "id") {
        return [key, id];
      }
      if (value instanceof z.ZodDefault)
        return [key, value._def.defaultValue()];
      return [key, undefined];
    })
  );
}
export function getEditValues<Schema extends z.AnyZodObject>(
  schema: Schema,
  data: z.infer<Schema>
) {
  return Object.fromEntries(
    Object.entries(schema.shape).map(([key, value]) => {
      if (value instanceof z.ZodDefault)
        return [key, data[key] ?? value._def.defaultValue()];
      return [key, undefined];
    })
  );
}

export const useActiveSection = (items: string[]) => {
  const [activeId, setActiveId] = useState<string | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            setActiveId(entry.target.id);
          }
        }
      },
      { rootMargin: "0% 0% -80% 0%" }
    );

    if (items) {
      for (const id of items) {
        const element = document.getElementById(id);
        if (element) {
          observer.observe(element);
        }
      }
    }

    return () => {
      if (items) {
        for (const id of items) {
          const element = document.getElementById(id);
          if (element) {
            observer.unobserve(element);
          }
        }
      }
    };
  }, [items]);

  return activeId ?? undefined;
};
export function workOrderSumQuantities(
  workOrder: WorkOrder,
  checkImproper = false
) {
  if (
    !workOrder.productionRecords ||
    !Array.isArray(workOrder.productionRecords)
  ) {
    return 0; // Return 0 if productionRecords is not an array
  }

  return workOrder.productionRecords.reduce((sum, record) => {
    // Add qty based on checkImproper flag and record.isImproper
    if (
      (checkImproper && record.isImproper) ||
      (!checkImproper && !record.isImproper)
    ) {
      return sum + (record.qty || 0); // If qty is undefined, add 0
    }
    return sum;
  }, 0);
}
export function workOrderSum(workOrders: Array<WorkOrder>) {
  if (!Array.isArray(workOrders)) {
    return 0; // workOrders bir dizi değilse 0 döner
  }
  return workOrders.reduce((sum, workOrder) => {
    if (workOrder.status == "canceled") {
      return 0;
    }
    return sum + workOrder.qty;
  }, 0);
}
export function workOrderSumDeep(workOrders: Array<WorkOrder>) {
  if (!Array.isArray(workOrders)) {
    return 0; // workOrders bir dizi değilse 0 döner
  }
  return workOrders.reduce((sum, workOrder) => {
    return sum + workOrderSumQuantities(workOrder);
  }, 0);
}
export function calculatePercentage(total: number, completed: number) {
  if (total === 0) {
    return 0; // Bölenin (total) sıfır olmaması gerekir, aksi takdirde 0 döner
  }
  return (completed / total) * 100; // Yüzdelik oranı hesaplar
}

export function calcStats(workOrder: WorkOrderSummary) {
  let properQuantity = 0;
  let improperQuantity = 0;

  workOrder.productionRecords &&
    workOrder.productionRecords.forEach((record) => {
      if (record.isImproper) {
        improperQuantity += record.qty;
      } else {
        properQuantity += record.qty;
      }
    });

  const productionPercentage =
    workOrder.qty === 0 ? 0 : (properQuantity / workOrder.qty) * 100;

  return {
    total: workOrder.qty,
    proper: properQuantity,
    improper: improperQuantity,
    percentage: productionPercentage,
  };
}
interface Next7Days {
  day: number;
  month: string;
  year: number;
  day_text: string;
}
export function useNext7Days(): Array<Next7Days> {
  return useMemo(() => {
    const today = new Date();
    const days = [];

    for (let i = 0; i < 7; i++) {
      const nextDay = new Date(today);
      nextDay.setDate(today.getDate() + i);

      const dayObject = {
        day: nextDay.getDate(),
        month: ("0" + (nextDay.getMonth() + 1)).slice(-2),
        year: nextDay.getFullYear(),
        day_text: nextDay
          .toLocaleDateString(undefined, { weekday: "short" })
          .toLowerCase(),
      };

      days.push(dayObject);
    }

    return days;
  }, []);
}
export function capitalizeFirstLetter(text: string) {
  return text.charAt(0).toLocaleUpperCase() + text.slice(1).toLocaleLowerCase();
}
export function convertToFloat(value: number, decimalPlaces: number) {
  const stringValue = value.toString();
  const integerPart = stringValue.slice(0, -decimalPlaces) || "0";
  const decimalPart = stringValue.slice(-decimalPlaces);
  return parseFloat(`${integerPart}.${decimalPart}`);
}
export function determineDecimalPlaces(value: string) {
  const valueString = value.toString();
  const dotPosition = valueString.indexOf(".");

  if (dotPosition === -1) {
    return 2;
  }

  return valueString.length - dotPosition - 1;
}

export function calcProductionPerformance(
  productTime: number,
  lossPercentage: number = 10
) {
  const totalSeconds = 8 * 60 * 60;

  const lossSeconds = (totalSeconds * lossPercentage) / 100;
  const remainingSeconds = totalSeconds - lossSeconds;

  return Math.floor(remainingSeconds / productTime);
}
