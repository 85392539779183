import { Toaster as SonnerToaster } from "@/components/ui/sonner";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { Toaster } from "@/components/ui/toaster.tsx";
import "./index.css";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
    <Toaster />
    <SonnerToaster />
  </React.StrictMode>
);
