import AppMenuSidebarWrapper from "@/contexts/app_menu_sidebar";
import { Auth, TOKEN } from "@/contexts/auth";
import { LanguageProvider } from "@/contexts/language";
import { ThemeProvider } from "@/contexts/theme";
import { GraphQL } from "@/lib/graphql";
import { language, messages } from "@/localization";
import { RouteManager } from "@/routes/route_manager";
import { RecoilRoot } from "recoil";

function App() {
  return (
    <>
      <GraphQL sessionKey={TOKEN}>
        <RecoilRoot>
          <LanguageProvider messages={messages} initLocale={language}>
            <Auth>
              <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
                <AppMenuSidebarWrapper>
                  <RouteManager />
                </AppMenuSidebarWrapper>
              </ThemeProvider>
            </Auth>
          </LanguageProvider>
        </RecoilRoot>
      </GraphQL>
    </>
  );
}

export default App;
