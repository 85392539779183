import type { DocumentNode } from "graphql";
import { gql } from "graphql-tag";

export const LOGIN: DocumentNode = gql`
  mutation ($email: String!, $password: String!) {
    appUserLogin(email: $email, password: $password) {
      name
      surname
      appUserId
      userId
      email
      isActive
      identifier
      lang
      tenant {
        name
        title
      }
      title
      profilePhoto
      token {
        authToken
        platform
        validUntil
      }
      permissions
    }
  }
`;

export const ON_SITE_USER_LOGIN_MUTATION: DocumentNode = gql`
  mutation OnSiteUserLogin(
    $tenantId: ID!
    $onSiteUserId: ID!
    $userId: ID!
    $clientHash: ID!
  ) {
    onSiteUserLogin(
      tenantId: $tenantId
      onSiteUserId: $onSiteUserId
      userId: $userId
      clientHash: $clientHash
    ) {
      name
      surname
      onSiteUserId
      userId
      isActive
      identifier
      title
      tenant {
        name
        title
      }
      permissions
      profilePhoto
      token {
        authToken
        platform
        validUntil
        platformSpecificId
      }
    }
  }
`;

export const ON_SITE_USER_LOGOUT_MUTATION: DocumentNode = gql`
  mutation OnSiteUserLogout(
    $tenantId: ID!
    $onSiteUserId: ID!
    $userId: ID!
    $clientHash: ID!
  ) {
    onSiteUserLogout(
      tenantId: $tenantId
      onSiteUserId: $onSiteUserId
      userId: $userId
      clientHash: $clientHash
    ) {
      status
    }
  }
`;

export const ON_SITE_USER_LOGIN_SUBSCRIPTION: DocumentNode = gql`
  subscription ($clientHash: ID!) {
    onSiteUserLoginSubscription(clientHash: $clientHash) {
      name
      surname
      onSiteUserId
      userId
      isActive
      identifier
      tenant {
        name
        title
      }
      profilePhoto
      token {
        authToken
        platform
        validUntil
        platformSpecificId
      }
    }
  }
`;
export const ON_SITE_USER_LOGOUT_SUBSCRIPTION: DocumentNode = gql`
  subscription ($clientHash: ID!) {
    onSiteUserLogoutSubscription(clientHash: $clientHash) {
      status
    }
  }
`;

export const LOGOUT: DocumentNode = gql`
  mutation ($platform: String!) {
    appUserLogout(platform: $platform) {
      status
    }
  }
`;

export const CHANGE_MY_PASSWORD: DocumentNode = gql`
  mutation ChangeMyPassword(
    $oldPassword: String
    $newPassword: String
    $newPasswordAgain: String
  ) {
    changeMyPassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
      newPasswordAgain: $newPasswordAgain
    ) {
      name
    }
  }
`;

export const RESET_PASSWORD: DocumentNode = gql`
  mutation ResetPassword($email: String) {
    resetPassword(email: $email) {
      newPassword
    }
  }
`;
