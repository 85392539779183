import Cookie from "js-cookie";

import tr from "./tr.json";
export const messages = {
  tr,
};

export const language: string | undefined =
  Cookie.get("locale") !== undefined || Cookie.get("locale") !== "undefined"
    ? Cookie.get("locale")
    : navigator.language.split(/[-_]/)[0];
