import { useToast } from "@/components/ui/use-toast";
import { type LanguageContextProps, useLocale } from "@/contexts/language";
import {
  LOGIN,
  LOGOUT,
  ON_SITE_USER_LOGIN_SUBSCRIPTION,
  ON_SITE_USER_LOGOUT_SUBSCRIPTION,
} from "@/data/graphql/auth";
import { AuthType } from "@/enums/auth_type";
import { PODStatus } from "@/types";
import Cookies from "js-cookie";
import {
  type Dispatch,
  type FC,
  type ReactNode,
  type SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { useMutation, useSubscription } from "urql";

export interface IAuth {
  children: ReactNode;
}

export const TOKEN = "prodobit_token";
const TENANT = "prodobit_tenant";
const PLATFORM = "prodobit_platform";
const USER = "prodobit_user";
const USER_NAME_SURNAME = "prodobit_user_name_surname";
const USER_IDENTIFIER = "prodobit_user_identifier";
const USER_ROLES = "prodobit_user_roles";
const USER_PERMISSIONS = "prodobit_user_permissions";

/** Check token is valid or not */
export const isValidToken = (): boolean => {
  const token: string | null = sessionStorage.getItem(TOKEN);
  return !!token;
};

/** Check token is valid or not */
const setTokenToSession = (token: string): void => {
  sessionStorage.setItem(TOKEN, token);
};
/** Clear token from session */
const clearTokenFromSession = (): void => {
  sessionStorage.removeItem(TOKEN);
};
/** Set tenant information to session */
const setTenantToSession = (tenant: { name: string; title: string }): void => {
  sessionStorage.setItem(
    TENANT,
    JSON.stringify({ name: tenant.name, title: tenant.title })
  );
};
/** Clear tenant information from session */
const clearTenantFromSession = (): void => {
  sessionStorage.removeItem(TENANT);
};
/** Set platform information to session */
const setPlatformToSession = (platform: string): void => {
  sessionStorage.setItem(PLATFORM, platform);
};
/** Clear platform information from session */
const clearPlatformFromSession = (): void => {
  sessionStorage.removeItem(PLATFORM);
};
/** Set user information to session */
const setUserToSession = (user: User): void => {
  sessionStorage.setItem(USER, JSON.stringify(user));
  sessionStorage.setItem(USER_NAME_SURNAME, `${user.name} ${user.surname}`);
  sessionStorage.setItem(USER_IDENTIFIER, user.identifier);
};

/** Get user information from session */
const userFromSession = (): User | undefined => {
  return sessionStorage[USER] !== undefined
    ? JSON.parse(sessionStorage[USER])
    : undefined;
};
const getUserPermissionsFromSession = () => {
  return JSON.parse(sessionStorage[USER_PERMISSIONS] || "[]");
};
const setUserPermissionsToSession = (permissions: Array<string>) => {
  sessionStorage.setItem(USER_PERMISSIONS, JSON.stringify(permissions));
};

/** Clear user information from session */
const clearUserFromSession = (): void => {
  sessionStorage.removeItem(USER);
  sessionStorage.removeItem(USER_NAME_SURNAME);
  sessionStorage.removeItem(USER_IDENTIFIER);
  sessionStorage.removeItem(USER_PERMISSIONS);
};
/** Get user roles from session */
const userRolesFromSession = () => {
  return sessionStorage[USER_ROLES];
};

const getClientHashFromCookie = (): string | undefined => {
  return Cookies.get("client_hash");
};
const setClientHashToCookie = (value: string): void => {
  Cookies.set("client_hash", value);
};
// /** Set user roles to session */
// const setUserRolesToSession = (userRoles: any): void => {
//   sessionStorage.setItem(USER_ROLES, userRoles);
// };
// /** Clear user roles from session */
// const clearUserRolesFromSession = (): void => {
//   sessionStorage.removeItem(USER_ROLES);
// };

/** User Menu Interface */
export interface UserMenu {
  menuId: string;
  name: string;
  translationKey: string;
  to: string;
  icon: string;
}
export interface ITenant {
  nane: string;
  title: string;
}

/** User Interface */
export interface User {
  identifier: string;
  isActive: boolean;
  name: string;
  surname: string;
  title: string;
  tenant: ITenant;
  profilePhoto: string;
  onSiteUserId: string | undefined;
  appUserId: string | undefined;
  userMenu: UserMenu;
  token: {
    authToken: string;
    platform: string;
    validUntil: string;
  };
}

export interface AppUser extends User {
  appUserId: string;
  email: string;
}

export interface OnSiteUser extends User {
  onSiteUserId: string;
}

export interface SystemUser extends User {
  systemUserId: string;
  email: string;
}

export function unauthenticatedAccess() {
  clearTokenFromSession();
  clearPlatformFromSession();
  clearUserFromSession();
  clearTenantFromSession();
  window.location.reload();
}

export const Auth: FC<IAuth> = ({ children }) => {
  return (
    <>
      <InternalAuth>{children}</InternalAuth>
    </>
  );
};
export const InternalAuth: FC<IAuth> = ({ children }) => {
  const [authType, setAuthType] = useState<AuthType>(
    AuthType.email_with_password
  );
  const [mode, setMode] = useState<PODStatus>("key");
  const [isAuthenticated, makeAuthenticated] = useState<boolean>(
    isValidToken()
  );
  const [authError, setAuthError] = useState<boolean>(false);
  const [roles, setRoles] = useState(userRolesFromSession());
  const [permissions, setPermissions] = useState(
    getUserPermissionsFromSession()
  );
  const [user, setUser] = useState<User | undefined>(userFromSession());
  const [clientHash, setClientHashLocal] = useState<string | undefined>(
    getClientHashFromCookie()
  );
  const [, authFunc] = useMutation(LOGIN);
  const [, logoutFunc] = useMutation(LOGOUT);
  const { formatMessage } = useIntl();
  const { toast } = useToast();
  const { changeLanguage, locale } = useLocale() as LanguageContextProps;

  function setClientHash(value: string) {
    setClientHashLocal(value);
    setClientHashToCookie(value);
  }

  const [loginOnSiteResult] = useSubscription({
    query: ON_SITE_USER_LOGIN_SUBSCRIPTION,
    variables: { clientHash },
    pause: !clientHash, // clientHash yoksa aboneliği duraklat
  });

  const [logoutOnSiteResult] = useSubscription({
    query: ON_SITE_USER_LOGOUT_SUBSCRIPTION,
    variables: { clientHash },
    pause: !clientHash,
  });

  useEffect(() => {
    if (loginOnSiteResult.data) {
      setTokenToSession(
        loginOnSiteResult.data.onSiteUserLoginSubscription.token.authToken
      );
      setUserToSession(loginOnSiteResult.data.onSiteUserLoginSubscription);
      setPlatformToSession(
        loginOnSiteResult.data.onSiteUserLoginSubscription.token.platform
      );
      setUser(loginOnSiteResult.data.onSiteUserLoginSubscription);
      makeAuthenticated(true);
      setMode("entered");
    }
  }, [loginOnSiteResult.data]);

  useEffect(() => {
    if (logoutOnSiteResult.data) {
      if (logoutOnSiteResult.data.onSiteUserLogoutSubscription.status) {
        console.log("logged out!..");
        setMode("card");
        clearTokenFromSession();
        clearPlatformFromSession();
        clearUserFromSession();
        clearTenantFromSession();
        setUser(undefined);
        makeAuthenticated(false);
      } else {
        console.log("Can not logged out!..");
      }
    }
  }, [logoutOnSiteResult.data]);

  function authenticate(email: string, password: string) {
    setAuthError(false);
    authFunc({ email, password }).then((result) => {
      if (result.error) {
        setAuthError(true);
        toast({
          title: formatMessage({ id: "authentication_failed" }),
          description: formatMessage({
            id: "please_check_your_email_and_password_and_try_again",
          }),
          variant: "destructive",
        });
      }
      if (result.data) {
        if (result.data.appUserLogin) {
          setAuthError(false);
          setTokenToSession(result.data.appUserLogin.token.authToken);
          setPlatformToSession(result.data.appUserLogin.token.platform);
          setUserToSession(result.data.appUserLogin);
          setTenantToSession(result.data.appUserLogin.tenant);
          setUser(result.data.appUserLogin);
          makeAuthenticated(true);
          setPermissions(result.data.appUserLogin.permissions);
          setUserPermissionsToSession(result.data.appUserLogin.permissions);

          if (result.data.appUserLogin.lang !== locale) {
            changeLanguage(result.data.appUserLogin.lang);
          }
          toast({
            title: formatMessage({ id: "authentication_successful" }),
            description: formatMessage({
              id: "login_welcome_message",
            }),
          });
        } else {
          setAuthError(true);
          toast({
            title: formatMessage({ id: "authentication_failed" }),
            description: formatMessage({
              id: "your_user_is_not_active",
            }),
            variant: "destructive",
          });
        }
      }
    });
  }
  function _logout() {
    clearTokenFromSession();
    clearPlatformFromSession();
    clearUserFromSession();
    clearTenantFromSession();
    setUser(undefined);
    makeAuthenticated(false);
  }

  function logout() {
    return logoutFunc({ platform: sessionStorage.getItem(PLATFORM) }).then(
      (result) => {
        if (result.error) {
          _logout();
          return result;
        }
        if (result.data) {
          _logout();
          return result;
        }
      }
    );
  }

  const value = {
    isAuthenticated,
    makeAuthenticated,
    authenticate,
    logout,
    permissions,
    user,
    setUser,
    roles,
    setRoles,
    setPermissions,
    clientHash,
    setClientHash,
    authError,
    mode,
    setMode,
    authType,
    setAuthType,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export interface AuthContextProvider {
  /** User authentication state */
  isAuthenticated: boolean;
  /** Set user authentication state */
  makeAuthenticated: Dispatch<SetStateAction<boolean>>;
  /** Logout function */
  logout: () => void;
  /** Authenticate function */
  authenticate: (email: string, password: string) => void;
  /** List of roles */
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  roles: any;
  /** List of permission */
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  permissions: any;
  /** User information */
  user: User | undefined;
  /** Set user */
  setUser: Dispatch<SetStateAction<User | undefined>>;
  /** Set roles */
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  setRoles: (roles: any) => void;
  /** Set permissions */
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  setPermissions: (permissions: any) => void;

  authError: boolean;
  mode: PODStatus;
  setMode: (mode: PODStatus) => void;
  clientHash: string | undefined;
  setClientHash: Dispatch<string>;
  authType: AuthType;
  setAuthType: Dispatch<SetStateAction<AuthType>>;
}

/** Auth Context */
// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const AuthContext = createContext<AuthContextProvider>({} as any);

/** useAuth Hook */
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw Error("Component has to be wrapped into todo context provider");
  }
  return context;
};

export default Auth;
