import Cookie from "js-cookie";
import { type FC, createContext, useContext, useState } from "react";
import { IntlProvider, type MessageFormatElement } from "react-intl";

export type LanguageContextProps = {
  locale: string;
  changeLanguage: (newLocale: string) => void;
};
export const LanguageContext = createContext<LanguageContextProps | undefined>(
  undefined
);

interface LanguageProviderProps {
  children: JSX.Element;
  messages: Record<
    string,
    Record<string, string> | Record<string, MessageFormatElement[]>
  >;

  initLocale: string | undefined;
}
export const LanguageProvider: FC<LanguageProviderProps> = ({
  children,
  messages,
  initLocale,
}: LanguageProviderProps) => {
  const [locale, setLocale] = useState<LanguageContextProps["locale"]>(
    initLocale ?? "tr"
  );
  const changeLanguage = (newLocale: string): void => {
    setLocale(newLocale);
    document.documentElement.lang = newLocale;
    Cookie.set("locale", newLocale);
  };

  return (
    <LanguageContext.Provider value={{ locale, changeLanguage }}>
      <IntlProvider locale={locale} messages={messages[locale]}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};
export const useLocale = () => useContext(LanguageContext);

export default LanguageProvider;
