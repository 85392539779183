import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useAppMenuSidebar } from "@/contexts/app_menu_sidebar";
import { cn } from "@/lib/utils";
import { ProdobitMenu } from "@/types";
import { FC, ReactNode } from "react";
import { useIntl } from "react-intl";
import { Link, LinkProps, useNavigate } from "react-router-dom";

export const Menu: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <TooltipProvider>
      <ul className="my-[7px]">{children}</ul>
    </TooltipProvider>
  );
};
export const MenuLogo: FC<{ to: LinkProps["to"] }> = ({ to }) => {
  return (
    <li className="relative flex h-[65px] w-full items-center justify-center mb-2">
      <Link
        to={to}
        className="relative flex h-8 w-8 items-center justify-center text-sm no-underline transition-all duration-100 ease-linear"
      >
        <img src="/icon.png" />
      </Link>
    </li>
  );
};
export const MenuItem: FC<{
  name: ProdobitMenu;
  icon: ReactNode;
  text: string;
}> = ({ icon, text, name }) => {
  const { selectedMenu, select } = useAppMenuSidebar();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  function go() {
    switch (name) {
      case "Dashboard":
        goTo("/");
        break;
      case "Settings":
        goTo("/settings");
        break;
      case "Messages":
        goTo("/messages");
        break;
      case "POD":
        goTo("/pod");
        break;
      default:
        select(name);
        break;
    }
  }
  function goTo(to: string) {
    select(name);
    navigate(to);
  }
  return (
    <li className="relative flex h-[65px] w-full items-center justify-center cursor-pointer">
      <Tooltip>
        <TooltipTrigger asChild>
          <div
            onClick={() => go()}
            className={cn(
              "flex h-9 w-9 items-center justify-center rounded-lg transition-colors hover:text-foreground md:h-8 md:w-8",
              selectedMenu === name
                ? "bg-accent text-accent-foreground"
                : "text-muted-foreground"
            )}
          >
            {icon}
            <span className="sr-only">{formatMessage({ id: text })}</span>
          </div>
        </TooltipTrigger>
        <TooltipContent side="right">
          {formatMessage({ id: text })}
        </TooltipContent>
      </Tooltip>
    </li>
  );
};
