import { Menu, MenuItem, MenuLogo } from "@/components/custom/menu";
import { ModeToggle } from "@/components/custom/mode-toggle";
import { UserDropdownMenu } from "@/components/custom/user_dropdown_menu";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useAppMenuSidebar } from "@/contexts/app_menu_sidebar";
import { useAuth } from "@/contexts/auth";
import { cn } from "@/lib/utils";
import { ProdobitMenu } from "@/types";
import { useClickOutside, useWindowScroll } from "@mantine/hooks";
import { createAclify } from "react-aclify";

import {
  BadgeDollarSign,
  DraftingCompass,
  Factory,
  HandCoins,
  LayoutDashboard,
  PanelLeft,
  Search,
  ServerCog,
  Settings2,
  Truck,
} from "lucide-react";
import { type FC } from "react";
import { useIntl } from "react-intl";
import { Link, Outlet } from "react-router-dom";

type Role = "user";
type Permission =
  | "factory:production-orders:create"
  | "factory:production-orders:update"
  | "factory:production-orders:delete"
  | "factory:products:read"
  | "factory:products:create"
  | "factory:products:update"
  | "factory:products:delete"
  | "factory:raw-materials:read"
  | "factory:raw-materials:create"
  | "factory:raw-materials:update"
  | "factory:raw-materials:delete"
  | "factory:planning:read"
  | "factory:planning:create"
  | "factory:planning:update"
  | "factory:settings:read"
  | "factory:settings:update"
  | "factory:overview"
  | "finance:overview"
  | "finance:inventories:read"
  | "finance:inventories:create"
  | "finance:inventories:update"
  | "finance:inventories:delete"
  | "finance:employees:read"
  | "finance:employees:create"
  | "finance:employees:update"
  | "finance:employees:delete"
  | "quality:overview"
  | "quality:items:read"
  | "quality:items:create"
  | "quality:items:update"
  | "quality:items:delete"
  | "quality:work-flows:read"
  | "quality:work-flows:create"
  | "quality:work-flows:update"
  | "quality:work-flows:delete"
  | "factory:production-orders:read"
  | "settings:overview"
  | "supply:overview"
  | "supply:suppliers:read"
  | "supply:suppliers:create"
  | "supply:suppliers:update"
  | "supply:suppliers:delete"
  | "supply:purchase-orders:read"
  | "supply:purchase-orders:create"
  | "supply:purchase-orders:update"
  | "supply:purchase-orders:delete"
  | "supply:goods-delivery-notes:read"
  | "supply:goods-delivery-notes:create"
  | "supply:goods-delivery-notes:update"
  | "supply:goods-delivery-notes:delete"
  | "supply:items:read"
  | "supply:items:create"
  | "supply:items:update"
  | "supply:items:delete"
  | "sales:overview"
  | "sales:customers:read"
  | "sales:customers:create"
  | "sales:customers:update"
  | "sales:customers:delete"
  | "sales:orders:read"
  | "sales:orders:create"
  | "sales:orders:update"
  | "sales:orders:delete"
  | "sales:products:read"
  | "sales:products:create"
  | "sales:products:update"
  | "sales:products:delete"
  | "sales:contacts:read"
  | "sales:contacts:create"
  | "sales:contacts:update"
  | "sales:contacts:delete"
  | "sales:leads:read"
  | "sales:leads:create"
  | "sales:leads:update"
  | "sales:leads:delete"
  | "sales:deals:read"
  | "sales:deals:create"
  | "sales:deals:update"
  | "sales:deals:delete"
  | "pod:quality-inspection:read"
  | "pod:quality-inspection:create"
  | "pod:quality-inspection:update"
  | "pod:quality-inspection:delete"
  | "pod:production-records:read"
  | "pod:production-records:create"
  | "pod:production-records:update"
  | "pod:production-records:delete"
  | "pod:note:read"
  | "pod:note:create"
  | "pod:note:update"
  | "pod:note:delete";
export const { CanAccess, useAclify, AclifyProvider } = createAclify<
  Role,
  Permission
>();

export const Layout: FC = () => {
  const { permissions } = useAuth();
  return (
    <AclifyProvider userRoles={["user"]} userPermissions={permissions}>
      <LayoutComp />
    </AclifyProvider>
  );
};
const LayoutComp: FC = () => {
  const [scroll] = useWindowScroll();
  const { isOpen, selectedMenu } = useAppMenuSidebar();
  const { formatMessage } = useIntl();
  const { user } = useAuth();
  const BAR_BASE_CLASSES =
    "relative z-[1] flex h-[60px] w-full items-center justify-between rounded-2xl border transition-all duration-300";
  const BAR_DEFAULT_CLASSES = "border-transparent";
  const BAR_SCROLL_CLASSES =
    "mt-4 border-muted-200 bg-background px-2 shadow-lg shadow-muted-300/30 dark:border-muted-800 dark:bg-muted-950 dark:shadow-muted-800/30";
  const { isAuthorized } = useAclify();
  return (
    <div className="min-h-screen overflow-hidden transition-all duration-300 dark:bg-muted-900 bg-muted-50">
      <nav
        className={cn(
          "fixed start-0 top-0 z-[39] w-20 overflow-visible border border-muted-200 bg-background transition-all duration-300 dark:border-muted-800 dark:bg-muted-950 lg:translate-x-0 -translate-x-full  h-full",
          !isOpen && "lg:m-3 lg:h-[calc(100%-1.5rem)] lg:rounded-2xl"
        )}
      >
        <div className="relative h-full">
          <Menu>
            <MenuLogo to="/" />
            <MenuItem
              icon={<LayoutDashboard className="h-5 w-5" />}
              text="dashboard"
              name={ProdobitMenu.Dashboard}
            />
            {user && user.appUserId && (
              <>
                {isAuthorized({
                  roles: ["user"],
                  permissions: ["factory:overview"],
                }) && (
                  <MenuItem
                    icon={<Factory className="h-5 w-5" />}
                    text="factory"
                    name={ProdobitMenu.Factory}
                  />
                )}
                {isAuthorized({
                  roles: ["user"],
                  permissions: ["sales:overview"],
                }) && (
                  <MenuItem
                    icon={<HandCoins className="h-5 w-5" />}
                    text="sales"
                    name={ProdobitMenu.Sales}
                  />
                )}

                {isAuthorized({
                  roles: ["user"],
                  permissions: ["finance:overview"],
                }) && (
                  <MenuItem
                    icon={<BadgeDollarSign className="h-5 w-5" />}
                    text="finance"
                    name={ProdobitMenu.Finance}
                  />
                )}

                {isAuthorized({
                  roles: ["user"],
                  permissions: ["supply:overview"],
                }) && (
                  <MenuItem
                    icon={<Truck className="h-5 w-5" />}
                    text="supply"
                    name={ProdobitMenu.Supply}
                  />
                )}

                {isAuthorized({
                  roles: ["user"],
                  permissions: ["quality:overview"],
                }) && (
                  <MenuItem
                    icon={<DraftingCompass className="h-5 w-5" />}
                    text="quality"
                    name={ProdobitMenu.Quality}
                  />
                )}
              </>
            )}
            <MenuItem
              icon={<ServerCog className="h-5 w-5" />}
              text="production_operations"
              name={ProdobitMenu.POD}
            />
            {/* <MenuItem
              icon={<BookUser className="h-5 w-5" />}
              text="human_resources"
              name={ProdobitMenu.HR}
            />
            <MenuItem
              icon={<MessageSquare className="h-5 w-5" />}
              text="messages"
              name={ProdobitMenu.Messages}
            />
             */}
            <MenuItem
              icon={<Settings2 className="h-5 w-5" />}
              text="settings"
              name={ProdobitMenu.Settings}
            />
          </Menu>
        </div>
        <div className="absolute bottom-0 start-0 my-[7px] w-full">
          <div className="relative flex h-[65px] w-full items-center justify-center">
            {user && <UserDropdownMenu user={user} />}
          </div>
        </div>
      </nav>
      {selectedMenu !== "Dashboard" &&
        selectedMenu !== "Settings" &&
        selectedMenu !== "POD" &&
        selectedMenu !== "Messages" &&
        isOpen && <Second />}

      <div
        className={cn(
          "fixed start-0 top-0 z-10 w-full transition-all duration-300 lg:ms-20 lg:w-[calc(100%-80px)] lg:px-12",
          isOpen && "translate-x-[250px]"
        )}
      >
        <div className="relative mx-auto w-full px-4 lg:px-10 max-w-7xl xl:px-0">
          <div
            className={cn(
              BAR_BASE_CLASSES,
              scroll.y > 60 ? BAR_SCROLL_CLASSES : BAR_DEFAULT_CLASSES
            )}
          >
            <div className="flex flex-grow-[2] items-center md:max-w-[680px]">
              <div className="lg:hidden">
                <Sheet>
                  <SheetTrigger asChild>
                    <Button
                      variant="outline"
                      size="icon"
                      className="lg:hidden mr-6"
                    >
                      <PanelLeft className="h-6 w-6 text-muted-foreground" />
                    </Button>
                  </SheetTrigger>
                  <SheetContent side="left" className="sm:max-w-xs">
                    <Tabs defaultValue="account" orientation="vertical">
                      <TabsList>
                        <TabsTrigger value="account">Account</TabsTrigger>
                        <TabsTrigger value="password">Password</TabsTrigger>
                      </TabsList>
                      <TabsContent value="account">
                        Make changes to your account here.
                      </TabsContent>
                      <TabsContent value="password">
                        Change your password here.
                      </TabsContent>
                    </Tabs>
                  </SheetContent>
                </Sheet>
              </div>
              <div className="hidden w-full max-w-[380px] md:block">
                <div className="relative ml-auto flex-1 md:grow-0">
                  <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                  <Input
                    type="search"
                    disabled
                    placeholder={`${formatMessage({ id: "search" })}...`}
                    className="w-full rounded-lg bg-background pl-8 md:w-[200px] lg:w-[336px]"
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <ModeToggle />
              {user && <UserDropdownMenu user={user} />}
            </div>
          </div>
        </div>
      </div>
      <div
        className={cn(
          "sidebar-panel-float-wrapper relative min-h-screen transition-all duration-300 dark:bg-muted-900 lg:ms-20 lg:w-[calc(100%_-_80px)] pt-16 px-4 ptablet:px-6 ltablet:px-6 lg:px-12 pb-20  bg-muted-50",
          isOpen && "is-pushed translate-x-[250px]"
        )}
      >
        <div className="mx-auto max-w-7xl">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export const Second: FC = () => {
  const { isOpen, selectedMenu, open } = useAppMenuSidebar();
  const ref = useClickOutside(() => open(false));
  return (
    <nav
      ref={ref}
      id="sidebar-second"
      className={cn(
        "fixed start-0 top-0 z-[38] h-full w-[calc(100%-80px)] overflow-hidden border-0 border-muted-200 bg-background transition-all duration-300 dark:border-muted-800 dark:bg-muted-950 md:w-[220px] lg:start-20",
        isOpen && "is-sidebar-translated translate-x-0 border-r"
      )}
    >
      {selectedMenu === "Factory" && <FactoryMenu />}
      {selectedMenu === "Sales" && <SalesMenu />}
      {selectedMenu === "Finance" && <FinanceMenu />}
      {selectedMenu === "Supply" && <SupplyMenu />}
      {selectedMenu === "Quality" && <QualityMenu />}
      {selectedMenu === "HR" && <HRMenu />}
    </nav>
  );
};

function FactoryMenu() {
  const { formatMessage } = useIntl();
  const { isAuthorized } = useAclify();
  return (
    <>
      <div className="flex h-16 w-full items-center px-6">
        <h1 className="text-xl">{formatMessage({ id: "factory" })}</h1>
      </div>
      <div className="relative size-full overflow-y-auto">
        <div className="px-6 pb-8">
          {isAuthorized({
            roles: ["user"],
            permissions: ["factory:overview"],
          }) && (
            <Link
              to="factory/overview"
              className="block py-2 text-sm hover:text-primary text-muted-foreground"
            >
              {formatMessage({ id: "overview" })}
            </Link>
          )}
          {isAuthorized({
            roles: ["user"],
            permissions: ["factory:production-orders:read"],
          }) && (
            <Link
              to="factory/production-orders"
              className="block py-2 text-sm hover:text-primary text-muted-foreground"
            >
              {formatMessage({ id: "production_orders" })}
            </Link>
          )}
          {isAuthorized({
            roles: ["user"],
            permissions: ["factory:products:read"],
          }) && (
            <Link
              to="/factory/products"
              className="block py-2 text-sm hover:text-primary text-muted-foreground"
            >
              {formatMessage({ id: "products" })}
            </Link>
          )}

          {isAuthorized({
            roles: ["user"],
            permissions: ["factory:raw-materials:read"],
          }) && (
            <Link
              to="/factory/raw-materials"
              className="block py-2 text-sm hover:text-primary text-muted-foreground"
            >
              {formatMessage({ id: "raw_materials" })}
            </Link>
          )}
          {isAuthorized({
            roles: ["user"],
            permissions: ["factory:planning:read"],
          }) && (
            <Link
              to="/factory/planning"
              className="block py-2 text-sm hover:text-primary text-muted-foreground"
            >
              {formatMessage({ id: "planning" })}
            </Link>
          )}
          {isAuthorized({
            roles: ["user"],
            permissions: ["factory:settings:read"],
          }) && (
            <Link
              to="/factory/settings"
              className="block py-2 text-sm hover:text-primary text-muted-foreground"
            >
              {formatMessage({ id: "settings" })}
            </Link>
          )}
        </div>
      </div>
    </>
  );
}
function SalesMenu() {
  const { formatMessage } = useIntl();
  const { isAuthorized } = useAclify();
  return (
    <>
      <div className="flex h-16 w-full items-center px-6">
        <h1 className="text-xl">{formatMessage({ id: "sales" })}</h1>
      </div>
      <div className="relative size-full overflow-y-auto">
        <div className="px-6 pb-8">
          {isAuthorized({
            roles: ["user"],
            permissions: ["sales:overview"],
          }) && (
            <Link
              to="/sales/overview"
              className="block py-2 text-sm hover:text-primary text-muted-foreground"
            >
              {formatMessage({ id: "overview" })}
            </Link>
          )}
          {isAuthorized({
            roles: ["user"],
            permissions: ["sales:customers:read"],
          }) && (
            <Link
              to="/sales/customers"
              className="block py-2 text-sm hover:text-primary text-muted-foreground"
            >
              {formatMessage({ id: "customers" })}
            </Link>
          )}
          {isAuthorized({
            roles: ["user"],
            permissions: ["sales:orders:read"],
          }) && (
            <Link
              to="/sales/orders"
              className="block py-2 text-sm hover:text-primary text-muted-foreground"
            >
              {formatMessage({ id: "sales_orders" })}
            </Link>
          )}
          {isAuthorized({
            roles: ["user"],
            permissions: ["sales:products:read"],
          }) && (
            <Link
              to="/sales/products"
              className="block py-2 text-sm hover:text-primary text-muted-foreground"
            >
              {formatMessage({ id: "products" })}
            </Link>
          )}
          {isAuthorized({
            roles: ["user"],
            permissions: ["sales:contacts:read"],
          }) && (
            <Link
              to="/sales/contacts"
              className="block py-2 text-sm hover:text-primary text-muted-foreground"
            >
              {formatMessage({ id: "contacts" })}
            </Link>
          )}
          {isAuthorized({
            roles: ["user"],
            permissions: ["sales:leads:read"],
          }) && (
            <Link
              to="/sales/leads"
              className="block py-2 text-sm hover:text-primary text-muted-foreground"
            >
              {formatMessage({ id: "leads" })}
            </Link>
          )}
          {isAuthorized({
            roles: ["user"],
            permissions: ["sales:deals:read"],
          }) && (
            <Link
              to="/sales/deals"
              className="block py-2 text-sm hover:text-primary text-muted-foreground"
            >
              {formatMessage({ id: "deals" })}
            </Link>
          )}

          {/* <Link
            to="/sales/offers"
            className="block py-2 text-sm hover:text-primary text-muted-foreground"
          >
            {formatMessage({ id: "sales_offers" })}
          </Link> */}
        </div>
      </div>
    </>
  );
}

function FinanceMenu() {
  const { formatMessage } = useIntl();
  const { isAuthorized } = useAclify();
  return (
    <>
      <div className="flex h-16 w-full items-center px-6">
        <h1 className="text-xl">{formatMessage({ id: "finance" })}</h1>
      </div>
      <div className="relative size-full overflow-y-auto">
        <div className="px-6 pb-8">
          {isAuthorized({
            roles: ["user"],
            permissions: ["finance:overview"],
          }) && (
            <Link
              to="/finance/overview"
              className="block py-2 text-sm hover:text-primary text-muted-foreground"
            >
              {formatMessage({ id: "overview" })}
            </Link>
          )}
          {isAuthorized({
            roles: ["user"],
            permissions: ["finance:inventories:read"],
          }) && (
            <Link
              to="/finance/inventories"
              className="block py-2 text-sm hover:text-primary text-muted-foreground"
            >
              {formatMessage({ id: "inventories" })}
            </Link>
          )}

          {/* <Link
            to="/finance/accounts"
            className="block py-2 text-sm hover:text-primary text-muted-foreground"
          >
            {formatMessage({ id: "accounts" })}
          </Link> */}
          {/* <Link
            to="/finance/invoices"
            className="block py-2 text-sm hover:text-primary text-muted-foreground"
          >
            {formatMessage({ id: "invoices" })}
          </Link> */}
          {/* <Link
            to="/finance/payments"
            className="block py-2 text-sm hover:text-primary text-muted-foreground"
          >
            {formatMessage({ id: "payments" })}
          </Link> */}
          {isAuthorized({
            roles: ["user"],
            permissions: ["finance:employees:read"],
          }) && (
            <Link
              to="/finance/employees"
              className="block py-2 text-sm hover:text-primary text-muted-foreground"
            >
              {formatMessage({ id: "employees" })}
            </Link>
          )}
        </div>
      </div>
    </>
  );
}

function SupplyMenu() {
  const { formatMessage } = useIntl();
  const { isAuthorized } = useAclify();
  return (
    <>
      <div className="flex h-16 w-full items-center px-6">
        <h1 className="text-xl">{formatMessage({ id: "supply" })}</h1>
      </div>
      <div className="relative size-full overflow-y-auto">
        <div className="px-6 pb-8">
          {isAuthorized({
            roles: ["user"],
            permissions: ["supply:overview"],
          }) && (
            <Link
              to="/supply/overview"
              className="block py-2 text-sm hover:text-primary text-muted-foreground"
            >
              {formatMessage({ id: "overview" })}
            </Link>
          )}
          {isAuthorized({
            roles: ["user"],
            permissions: ["supply:suppliers:read"],
          }) && (
            <Link
              to="/supply/suppliers"
              className="block py-2 text-sm hover:text-primary text-muted-foreground"
            >
              {formatMessage({ id: "suppliers" })}
            </Link>
          )}

          {isAuthorized({
            roles: ["user"],
            permissions: ["supply:items:read"],
          }) && (
            <Link
              to="/supply/items"
              className="block py-2 text-sm hover:text-primary text-muted-foreground"
            >
              {formatMessage({ id: "items" })}
            </Link>
          )}

          {/* <Link
            to="/supply/stocks"
            className="block py-2 text-sm hover:text-primary text-muted-foreground"
          >
            {formatMessage({ id: "stocks" })}
          </Link> */}
          {/* <Link
            to="/supply/purchase-requests"
            className="block py-2 text-sm hover:text-primary text-muted-foreground"
          >
            {formatMessage({ id: "purchase_requests" })}
          </Link> */}
          {isAuthorized({
            roles: ["user"],
            permissions: ["supply:purchase-orders:read"],
          }) && (
            <Link
              to="/supply/purchase-orders"
              className="block py-2 text-sm hover:text-primary text-muted-foreground"
            >
              {formatMessage({ id: "purchase_orders" })}
            </Link>
          )}

          <Link
            to="/supply/goods-received-notes"
            className="block py-2 text-sm hover:text-primary text-muted-foreground"
          >
            {formatMessage({ id: "goods_received_notes" })}
          </Link>

          {isAuthorized({
            roles: ["user"],
            permissions: ["supply:goods-delivery-notes:read"],
          }) && (
            <Link
              to="/supply/goods-delivery-notes"
              className="block py-2 text-sm hover:text-primary text-muted-foreground"
            >
              {formatMessage({ id: "goods_delivery_notes" })}
            </Link>
          )}
        </div>
      </div>
    </>
  );
}

function QualityMenu() {
  const { formatMessage } = useIntl();
  const { isAuthorized } = useAclify();
  return (
    <>
      <div className="flex h-16 w-full items-center px-6">
        <h1 className="text-xl">{formatMessage({ id: "quality" })}</h1>
      </div>
      <div className="relative size-full overflow-y-auto">
        <div className="px-6 pb-8">
          {isAuthorized({
            roles: ["user"],
            permissions: ["quality:overview"],
          }) && (
            <Link
              to="/supply/overview"
              className="block py-2 text-sm hover:text-primary text-muted-foreground"
            >
              {formatMessage({ id: "overview" })}
            </Link>
          )}
          {isAuthorized({
            roles: ["user"],
            permissions: ["quality:work-flows:read"],
          }) && (
            <Link
              to="quality/work-flows"
              className="block py-2 text-sm hover:text-primary text-muted-foreground"
            >
              {formatMessage({ id: "work_flows" })}
            </Link>
          )}
          {isAuthorized({
            roles: ["user"],
            permissions: ["quality:items:read"],
          }) && (
            <Link
              to="/quality/items"
              className="block py-2 text-sm hover:text-primary text-muted-foreground"
            >
              {formatMessage({ id: "items" })}
            </Link>
          )}
        </div>
      </div>
    </>
  );
}

function HRMenu() {
  const { formatMessage } = useIntl();
  return (
    <>
      <div className="flex h-16 w-full items-center px-6">
        <h1 className="text-xl">{formatMessage({ id: "human_resources" })}</h1>
      </div>
      <div className="relative size-full overflow-y-auto">
        <div className="px-6 pb-8">
          <Link
            to="/human-resources/overview"
            className="block py-2 text-sm hover:text-primary text-muted-foreground"
          >
            {formatMessage({ id: "overview" })}
          </Link>
        </div>
      </div>
    </>
  );
}

export default Layout;
