import { useAuth } from "@/contexts/auth";
import Layout from "@/layouts";
import { AuthLayout } from "@/layouts/auth";
import Dashboard from "@/pages";
import {
  Accounts,
  CashReceivables,
  Commerce,
  CommerceAnalytics,
  ContactDetails,
  Contacts,
  ContractManufacturing,
  Customers,
  DealDetails,
  Deals,
  EmployeeDetails,
  Employees,
  Expenses,
  Factory,
  FactoryNewRawMaterial,
  FactoryProductDetails,
  FactoryProducts,
  FactoryRawMaterialDetails,
  FactoryRawMaterials,
  FactoryResources,
  FactorySettings,
  Finance,
  GoodsDeliveryNotes,
  GoodsReceivedNotes,
  Inventory,
  Invoices,
  LeadDetails,
  Leads,
  Login,
  NewLead,
  NewOrder,
  NewProduct,
  NewPurchaseOrder,
  NotFound,
  Offers,
  OrderDetails,
  Orders,
  POD,
  PODWorkOrder,
  Planning,
  ProductDetails,
  ProductionLines,
  ProductionOrderDetails,
  ProductionOrderWorkOrder,
  ProductionOrders,
  Products,
  PurchaseOrderDetails,
  PurchaseOrders,
  PurchaseRequests,
  Quality,
  QualityItems,
  QualityItemsDetails,
  SettingsLayout,
  Suppliers,
  Supply,
  SupplyInventory,
  SupplyItems,
  SupplyItemsDetails,
  Warehouses,
  WorkFlowDetails,
  WorkFlows,
} from "@/routes/route_loader";
import type { FC, ReactNode } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

const AuthCheck: FC<{ children: ReactNode }> = ({ children }) => {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.isAuthenticated && location.pathname !== "/login") {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return children;
};

export const RouteManager = () => {
  return (
    <BrowserRouter>
      <AuthCheck>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/settings" element={<SettingsLayout />} />
            <Route path="/pod">
              <Route path="/pod" element={<POD />} />
              <Route path="/pod/work-order/:id" element={<PODWorkOrder />} />
            </Route>
            <Route path="/sales">
              <Route path="/sales/overview" element={<Commerce />} />
              <Route path="/sales/customers" element={<Customers />} />
              <Route path="/sales/offers" element={<Offers />} />
              <Route path="/sales/orders">
                <Route path="/sales/orders" element={<Orders />} />
                <Route path="/sales/orders/:id" element={<OrderDetails />} />
                <Route path="/sales/orders/new" element={<NewOrder />} />
              </Route>
              <Route path="/sales/products">
                <Route path="/sales/products" element={<Products />} />
                <Route path="/sales/products/new" element={<NewProduct />} />
                <Route
                  path="/sales/products/:id"
                  element={<ProductDetails />}
                />
              </Route>
              <Route path="/sales/deals">
                <Route path="/sales/deals" element={<Deals />} />
                <Route path="/sales/deals/:id" element={<DealDetails />} />
              </Route>
              <Route path="/sales/leads">
                <Route path="/sales/leads" element={<Leads />} />
                <Route path="/sales/leads/new" element={<NewLead />} />
                <Route path="/sales/leads/:id" element={<LeadDetails />} />
              </Route>
              <Route path="/sales/contacts">
                <Route path="/sales/contacts" element={<Contacts />} />
                <Route
                  path="/sales/contacts/:id"
                  element={<ContactDetails />}
                />
              </Route>
              <Route
                path="/sales/contract-manufacturing"
                element={<ContractManufacturing />}
              />
              <Route path="/sales/analytics" element={<CommerceAnalytics />} />
            </Route>
            <Route path="/factory">
              <Route path="/factory/overview" element={<Factory />} />
              <Route path="/factory/production-orders">
                <Route
                  path="/factory/production-orders"
                  element={<ProductionOrders />}
                />
                <Route
                  path="/factory/production-orders/:id"
                  element={<ProductionOrderDetails />}
                />
                <Route
                  path="/factory/production-orders/work-order/:id"
                  element={<ProductionOrderWorkOrder />}
                />
              </Route>

              <Route
                path="/factory/orders/:id"
                element={<ProductionOrderDetails />}
              />
              <Route path="/factory/products">
                <Route path="/factory/products" element={<FactoryProducts />} />
                <Route
                  path="/factory/products/:id"
                  element={<FactoryProductDetails />}
                />
              </Route>
              <Route path="/factory/production-lines">
                <Route
                  path="/factory/production-lines"
                  element={<ProductionLines />}
                />
                {/* <Route
                  path="/factory/production-lines/:id"
                  element={<FactoryProductDetails />}
                /> */}
              </Route>
              <Route path="/factory/raw-materials">
                <Route
                  path="/factory/raw-materials"
                  element={<FactoryRawMaterials />}
                />
                <Route
                  path="/factory/raw-materials/:id"
                  element={<FactoryRawMaterialDetails />}
                />
                <Route
                  path="/factory/raw-materials/new"
                  element={<FactoryNewRawMaterial />}
                />
              </Route>
              <Route path="/factory/resources" element={<FactoryResources />} />
              <Route path="/factory/planning" element={<Planning />} />
              <Route path="/factory/settings" element={<FactorySettings />} />
            </Route>
            <Route path="/finance">
              <Route path="/finance/overview" element={<Finance />} />
              <Route path="/finance/employees" element={<Employees />} />
              <Route
                path="/finance/employees/:id"
                element={<EmployeeDetails />}
              />
              <Route path="/finance/accounts" element={<Accounts />} />
              <Route path="/finance/inventories" element={<Inventory />} />
              <Route
                path="/finance/cash-receivables"
                element={<CashReceivables />}
              />
              <Route path="/finance/invoices" element={<Invoices />} />
              <Route path="/finance/expenses" element={<Expenses />} />
            </Route>
            <Route path="/supply">
              <Route path="/supply/overview" element={<Supply />} />
              <Route path="/supply/suppliers" element={<Suppliers />} />
              <Route path="/supply/items">
                <Route path="/supply/items" element={<SupplyItems />} />
                <Route
                  path="/supply/items/:id"
                  element={<SupplyItemsDetails />}
                />
              </Route>
              <Route
                path="/supply/purchase-requests"
                element={<PurchaseRequests />}
              />
              <Route
                path="/supply/purchase-orders"
                element={<PurchaseOrders />}
              />
              <Route
                path="/supply/purchase-orders/:id"
                element={<PurchaseOrderDetails />}
              />
              <Route
                path="/supply/purchase-orders/new"
                element={<NewPurchaseOrder />}
              />
              <Route path="/supply/warehouses" element={<Warehouses />} />
              <Route path="/supply/inventory" element={<SupplyInventory />} />
              <Route
                path="/supply/goods-received-notes"
                element={<GoodsReceivedNotes />}
              />
              <Route
                path="/supply/goods-delivery-notes"
                element={<GoodsDeliveryNotes />}
              />
            </Route>
            <Route path="/quality">
              <Route path="/quality/overview" element={<Quality />} />
              <Route path="/quality/work-flows">
                <Route path="/quality/work-flows" element={<WorkFlows />} />
                <Route
                  path="/quality/work-flows/:id"
                  element={<WorkFlowDetails />}
                />
              </Route>
              <Route path="/quality/items" element={<QualityItems />} />
              <Route
                path="/quality/items/:id"
                element={<QualityItemsDetails />}
              />
            </Route>
          </Route>
          <Route element={<AuthLayout />}>
            <Route path="/login" element={<Login />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthCheck>
    </BrowserRouter>
  );
};
