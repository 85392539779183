import { TOKEN, unauthenticatedAccess } from "@/contexts/auth";
import * as withAbsintheSocket from "@absinthe/socket";
import { Socket as PhoenixSocket } from "phoenix";
import type { FunctionComponent, ReactNode } from "react";
import type { Client as IClient } from "urql";
import {
  Client,
  Provider,
  cacheExchange,
  errorExchange,
  fetchExchange,
  subscriptionExchange,
} from "urql";

export interface IGraphQL {
  children?: ReactNode | undefined;
  sessionKey: string;
}

const phoenixSocket = new PhoenixSocket("wss://ex.prodobit.app/graphql", {
  params: () => {
    if (sessionStorage.getItem(TOKEN)) {
      return { token: sessionStorage.getItem(TOKEN) };
    }
    return {};
  },
});
const absintheSocket = withAbsintheSocket.create(phoenixSocket);
export const client: IClient = new Client({
  url: "https://ex.prodobit.app/graphql",

  fetchOptions: () => {
    const token = sessionStorage.getItem(TOKEN);
    return {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
      fetch: async (url: RequestInfo, options: RequestInit) => {
        const response = await window.fetch(url, options);
        if (!response.ok) {
          const errorResult = await response.json();
          console.error("GraphQL Error:", errorResult);
        }
        return response;
      },
    };
  },
  exchanges: [
    errorExchange({
      onError: (error) => {
        if (error) {
          console.log("Error:", error);
          if (error.name === "CombinedError") {
            if (error.message === "[GraphQL] Unauthenticated access") {
              console.log("Unauthenticated access");
              unauthenticatedAccess();
            }
          } else {
            if (error.graphQLErrors[0].message === "Unauthenticated access") {
              unauthenticatedAccess();
            }
          }
        }
      },
    }),
    cacheExchange,
    fetchExchange,
    subscriptionExchange({
      //@ts-ignore
      forwardSubscription: (operation) => {
        const notifier = withAbsintheSocket.send(absintheSocket, {
          // @ts-ignore
          operation: operation.query,
          variables: operation.variables,
        });
        return withAbsintheSocket.toObservable(absintheSocket, notifier);
      },
      enableAllOperations: true,
    }),
  ],
});
export const GraphQL: FunctionComponent<IGraphQL> = ({ children }) => {
  return <Provider value={client}>{children}</Provider>;
};
export default GraphQL;
