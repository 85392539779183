export enum ProdobitMenu {
  "Dashboard" = "Dashboard",
  "Factory" = "Factory",
  "R&D" = "R&D",
  "Finance" = "Finance",
  "Quality" = "Quality",
  "Supply" = "Supply",
  "Sales" = "Sales",
  "HR" = "HR",
  "Settings" = "Settings",
  "Messages" = "Messages",
  "POD" = "POD",
}
export type ProdobitMenuType = keyof typeof ProdobitMenu;
export type PODStatus = "key" | "card" | "success" | "error" | "entered";
