import { ProdobitMenu, ProdobitMenuType } from "@/types";
import {
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

type AppMenuSidebarContextProps = {
  isOpen: boolean;
  open: React.Dispatch<SetStateAction<boolean>>;
  selectedMenu: ProdobitMenuType;
  select: (menu: ProdobitMenu) => void;
};

const AppMenuSidebarContext = createContext<AppMenuSidebarContextProps>({
  isOpen: false,
  // biome-ignore lint/suspicious/noEmptyBlockStatements: <explanation>
  open: () => {},
  selectedMenu: ProdobitMenu.Dashboard,
  // biome-ignore lint/suspicious/noEmptyBlockStatements: <explanation>
  select: () => {},
});

const NonSideBarMenus = [
  ProdobitMenu.Dashboard,
  ProdobitMenu.Settings,
  ProdobitMenu.POD,
  ProdobitMenu.Messages,
];

export function AppMenuSidebarWrapper({ children }: { children: ReactNode }) {
  const [selectedMenu, setSelectedMenu] = useState<ProdobitMenuType>(
    ProdobitMenu.Dashboard
  );
  const [isOpen, open] = useState<boolean>(false);
  function select(menu: ProdobitMenu) {
    open(!NonSideBarMenus.includes(menu));
    setSelectedMenu(menu);
  }

  const value = {
    isOpen,
    open,
    selectedMenu,
    select,
  };

  return (
    <AppMenuSidebarContext.Provider value={value}>
      {children}
    </AppMenuSidebarContext.Provider>
  );
}

export function useAppMenuSidebar() {
  const context = useContext(
    AppMenuSidebarContext as React.Context<AppMenuSidebarContextProps>
  );
  if (!context) {
    throw new Error(
      "useAppMenuSidebar must be used within a AppMenuSidebarContext"
    );
  }
  return context;
}

export default AppMenuSidebarWrapper;
