import { Loader } from "@/components/loader";
import loadable from "@loadable/component";

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const LoadableWrapper = (component: any) =>
  loadable(() => component, { fallback: <Loader /> });
export const NotFound = LoadableWrapper(import("@/pages/not_found"));
export const Login = LoadableWrapper(import("@/pages/auth"));
export const Commerce = LoadableWrapper(import("@/pages/sales"));
export const Factory = LoadableWrapper(import("@/pages/factory"));
export const Finance = LoadableWrapper(import("@/pages/finance"));
export const Supply = LoadableWrapper(import("@/pages/supply"));
export const Quality = LoadableWrapper(import("@/pages/quality"));
export const Customers = LoadableWrapper(import("@/pages/sales/customers"));
export const POD = LoadableWrapper(import("@/pages/pod"));
export const PODWorkOrder = LoadableWrapper(import("@/pages/pod/work_order"));

export const Orders = LoadableWrapper(import("@/pages/sales/orders"));
export const OrderDetails = LoadableWrapper(
  import("@/pages/sales/orders/detail")
);
export const NewOrder = LoadableWrapper(import("@/pages/sales/orders/new"));
export const Products = LoadableWrapper(import("@/pages/sales/products"));
export const ProductDetails = LoadableWrapper(
  import("@/pages/sales/products/details")
);
export const NewProduct = LoadableWrapper(import("@/pages/sales/products/new"));

export const CommerceAnalytics = LoadableWrapper(
  import("@/pages/sales/analytics")
);

export const FactoryRawMaterials = LoadableWrapper(
  import("@/pages/factory/raw_materials")
);
export const FactoryRawMaterialDetails = LoadableWrapper(
  import("@/pages/factory/raw_materials/details")
);

export const FactoryNewRawMaterial = LoadableWrapper(
  import("@/pages/factory/raw_materials/new")
);

export const FactoryProducts = LoadableWrapper(
  import("@/pages/factory/products")
);
export const FactoryProductDetails = LoadableWrapper(
  import("@/pages/factory/products/details")
);

export const ProductionOrderDetails = LoadableWrapper(
  import("@/pages/factory/production_orders/details")
);

export const ProductionOrderWorkOrder = LoadableWrapper(
  import("@/pages/factory/production_orders/work_order")
);

export const FactoryResources = LoadableWrapper(
  import("@/pages/factory/resources")
);
export const ProductionLines = LoadableWrapper(
  import("@/pages/factory/production_lines")
);
export const FactorySettings = LoadableWrapper(
  import("@/pages/factory/settings")
);
export const Offers = LoadableWrapper(import("@/pages/sales/offers"));
export const ContractManufacturing = LoadableWrapper(
  import("@/pages/sales/contract_manufacturing")
);

export const ProductionOrders = LoadableWrapper(
  import("@/pages/factory/production_orders")
);

export const Employees = LoadableWrapper(import("@/pages/finance/employees"));
export const EmployeeDetails = LoadableWrapper(
  import("@/pages/finance/employees/details")
);
export const Accounts = LoadableWrapper(import("@/pages/finance/accounts"));
export const Inventory = LoadableWrapper(import("@/pages/finance/inventory"));
export const CashReceivables = LoadableWrapper(
  import("@/pages/finance/cash_receivables")
);
export const Invoices = LoadableWrapper(import("@/pages/finance/invoices"));
export const Expenses = LoadableWrapper(import("@/pages/finance/expenses"));

export const Suppliers = LoadableWrapper(import("@/pages/supply/suppliers"));
export const GoodsDeliveryNotes = LoadableWrapper(
  import("@/pages/supply/GoodsDeliveryNotes")
);
export const GoodsReceivedNotes = LoadableWrapper(
  import("@/pages/supply/GoodsReceivedNotes")
);
export const PurchaseOrders = LoadableWrapper(
  import("@/pages/supply/purchase_orders")
);
export const NewPurchaseOrder = LoadableWrapper(
  import("@/pages/supply/purchase_orders/new")
);
export const PurchaseOrderDetails = LoadableWrapper(
  import("@/pages/supply/purchase_orders/details")
);
export const PurchaseRequests = LoadableWrapper(
  import("@/pages/supply/purchase_requests")
);
export const SupplyInventory = LoadableWrapper(
  import("@/pages/supply/inventory")
);
export const Warehouses = LoadableWrapper(import("@/pages/supply/warehouses"));

export const SettingsLayout = LoadableWrapper(import("@/pages/settings"));
export const Planning = LoadableWrapper(import("@/pages/factory/planning"));
export const QualityItems = LoadableWrapper(import("@/pages/quality/items"));
export const QualityItemsDetails = LoadableWrapper(
  import("@/pages/quality/items/details")
);

export const Contacts = LoadableWrapper(import("@/pages/sales/contacts"));
export const ContactDetails = LoadableWrapper(
  import("@/pages/sales/contacts/details")
);
export const Deals = LoadableWrapper(import("@/pages/sales/deals"));
export const DealDetails = LoadableWrapper(
  import("@/pages/sales/deals/details")
);

export const Leads = LoadableWrapper(import("@/pages/sales/leads"));
export const LeadDetails = LoadableWrapper(
  import("@/pages/sales/leads/details")
);
export const NewLead = LoadableWrapper(import("@/pages/sales/leads/new"));
export const WorkFlows = LoadableWrapper(import("@/pages/quality/work_flows"));
export const WorkFlowDetails = LoadableWrapper(
  import("@/pages/quality/work_flows/details")
);
export const SupplyItems = LoadableWrapper(import("@/pages/supply/items"));
export const SupplyItemsDetails = LoadableWrapper(
  import("@/pages/supply/items/details")
);
